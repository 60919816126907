import axiosInstance from './server';
import {Listing} from "../utils/types";
// Define Types for StockX API (temporary, update with exact response structures)
export interface StockXListing {
	listingId: string;
	operationId?: string;
	operationStatus?: string;
	operationUrl?: string;
}
export interface CreateListingRequest {
	variantId: string;
	price: string;
	currencyCode: string;
	expiresInDays?: number;
}
export interface UpdateListingRequest {
	amount?: string;
	expiresAt?: string;
}
export interface StockXProduct {
	productId: string;
	styleId: string;
	title: string;
	brand: string;
	productType: string;
	productAttributes: {[key:string]: string};
	variants?: StockXVariant[];
}
export interface StockXVariant {
	variantId: string;
	variantName: string;
}


// **LISTINGS MANAGEMENT**
// Create a new listing
export const createStockXListing = async (data: Listing): Promise<StockXListing> => {
	const response = await axiosInstance.post<StockXListing>('/stockx/listing', data);
	return response.data;
};
// Get the status of a listing operation
export const getStockXListingStatus = async (operationId: string, operationUrl:string): Promise<StockXListing> => {
	const response = await axiosInstance.get<StockXListing>(`/stockx/listing/status/${operationId}?operationUrl=${operationUrl}`);
	return response.data;
};
// Activate a listing
export const activateStockXListing = async (listingId: string, data: Listing): Promise<void> => {
	await axiosInstance.put(`/stockx/listing/${listingId}/activate`);
};
// Deactivate a listing
export const deactivateStockXListing = async (listingId: string, data: Listing): Promise<void> => {
	await axiosInstance.put(`/stockx/listing/${listingId}/deactivate`);
};
// Update a listing
export const updateStockXListing = async (listingId: string, data: Listing): Promise<StockXListing> => {
	const response = await axiosInstance.patch<StockXListing>(`/stockx/listing/${listingId}`, data);
	return response.data;
};
// Delete a listing
export const deleteStockXListing = async (listingId: string, tnlId: string): Promise<void> => {
	await axiosInstance.delete(`/stockx/listing/${listingId}/delete?listingId=${tnlId}`);
};


// **PRODUCT SEARCH & VARIANTS**
// Search for a product
export const searchStockXProduct = async (query: string): Promise<any> => {
	const response = await axiosInstance.get<StockXProduct[]>(`/stockx/product?query=${query}`);
	return response.data;
};

// Get a product by ID
export const getStockXProductById = async (productId: string): Promise<StockXProduct> => {
	const response = await axiosInstance.get<StockXProduct>(`/stockx/product/${productId}`);
	return response.data;
};

// Get product variants
export const getStockXProductVariants = async (productId: string): Promise<any[]> => {
	const response = await axiosInstance.get<StockXVariant[]>(`/stockx/product/${productId}/variants`);
	return response.data;
};

// index.ts content
import { MarketplacePlatform, Platform } from "../../utils/types";
import stockXIcon from "../../images/stockx.svg";
import {BasePlatformOperations} from "../base";
import {ListingAccountVerificationCheckType, ListingAPIResponseType} from "../../types";
import {createStockXListing, updateStockXListing, deactivateStockXListing, activateStockXListing, deleteStockXListing, getStockXListingStatus, StockXListing} from "../../api/stockx";

export default {
	id: MarketplacePlatform.STOCKX,
	name: 'StockX',
	url: 'https://www.stockx.com',
	src: stockXIcon,
	alt: 'StockX',
	homepage: 'https://www.stockx.com',
	supportsApi: true,
	instructionSet: 'Follow StockX API instructions.',
	apiUrl: 'https://api.tracknlist.com/api/stockx/login',
	documentationUrl: 'https://stockx.com/docs'
} as Platform;


export class StockxPlatformOperations extends BasePlatformOperations{
	POLLING_INTERVAL = 5000; // 5 seconds
	TIMEOUT = 60000; // 60 seconds
	platform = "STOCKX"

	refreshToken = async(): Promise<ListingAccountVerificationCheckType> => {
		try {
			// // let's make a request to have the token refreshed.
			// const res = await axiosInstance.get(`/stockx/refresh`);
			// // let's update the access and the refresh token
			// await this.updateUserOnTokenRefresh(res.data.accessToken, res.data.refreshToken, res.data.expiry)
			return {
				isConnected: true,
				accessToken: "",
				platform: this.platform,
				message: `Account token successfully refreshed for platform: ${this.platform}`,
				expiry: + Date.now(), // Convert expiry time to milliseconds
			};
		} catch (error:any) {
			console.error("Error refreshing StockX token:", error.response?.data || error);
			return {
				isConnected: false,
				accessToken: "",
				platform: this.platform,
				message: `Error refreshing account token platform: ${this.platform}`,
				expiry: 0,
			};
		}
	}

	// /StockX/listing (POST)
	createStockXListing = async () => {
		try {
			if(!this.listing.description) this.listing.description = "N/A"
			const res = await createStockXListing(this.listing);
			console.log('Create listing response:', res);
			if(res.operationId && res.operationUrl) { this.pollStockXListingStatus(res.operationId, res.operationUrl).catch((err) => {console.log(err)}); }
		} catch (error) {
			console.error('Error creating listing:', error);
			throw error;
		}
	};

	pollStockXListingStatus = async (operationId: string, operationUrl:string): Promise<StockXListing | null> => {
		const startTime = Date.now();
		while (Date.now() - startTime < this.TIMEOUT) {
			try {
				const statusResponse = await getStockXListingStatus(operationId, operationUrl);
				console.log(`Polling StockX status: ${statusResponse.operationStatus}`);
				console.log(statusResponse);
				// Stop polling if the operation is complete
				if (statusResponse.operationStatus === "SUCCEEDED") {
					console.log("Listing successfully activated!");
					return statusResponse; // Return final listing status
				} else if (statusResponse.operationStatus === "FAILED") {
					console.log("Listing failed. Check StockX for details.");
					return statusResponse; // Return failure details
				}
				// Wait before the next request
				await new Promise((resolve) => setTimeout(resolve, this.POLLING_INTERVAL));
			} catch (error) {
				console.error("Error fetching StockX listing status:", error);
				return null; // Exit polling if an error occurs
			}
		}
		console.warn("Polling timed out. StockX listing status is still pending.");
		return null; // Return null if the operation is still pending after timeout
	};

	updateListing = async ():Promise<any> => {
		try {
			const res = await updateStockXListing(this.listing.id, this.listing);
			console.log('Create listing response:', res);
		} catch (error) {
			console.error('Error creating listing:', error);
			throw error;
		}
	};

	deListListing = async ():Promise<any> => {
		try {
			const res = await deactivateStockXListing(this.listing.listingObject["listingId"], this.listing);
			console.log('Create listing response:', res);
		} catch (error) {
			console.error('Error creating listing:', error);
			throw error;
		}
	};

	reListListing = async ():Promise<any> => {
		try {
			const res = await activateStockXListing(this.listing.listingObject["listingId"], this.listing);
			console.log('Create listing response:', res);
		} catch (error) {
			console.error('Error creating listing:', error);
			throw error;
		}
	};

	deleteListing = async ():Promise<any> => {
		try {
			const res = await deleteStockXListing(this.listing.listingObject["listingId"], this.listing.id);
			console.log('Create listing response:', res);
		} catch (error) {
			console.error('Error creating listing:', error);
			throw error;
		}
	};

	init = async(): Promise<ListingAPIResponseType> => {
		try {
			const data = await this.createStockXListing();
			console.log(data);
			this.cacheListingObjectInStore();
			// Success
			return {
				success: true,
				platform: this.platform,
				isError: false,
				listingId: "",
				message: "Listing successfully created and published on StockX",
				data: null,
			};

		} catch (error:any) {
			console.error("Error during StockX listing process:", error);
			this.removeCachedListingObjectInStore();
			if(error.response.data) {
				if(!['Success', 'Warning'].includes(error.response.data.status)) {
					return {
						success: false,
						platform: this.platform,
						isError: true,
						listingId: "",
						message: error.response.data.error || (error.response.data.errors ? error.response.data.errors.map((err:any) => typeof err === "object" ? err.ShortMessage : err).join(", ") : ""),
						data: null,
					}
				}
			}
			return {
				success: false,
				platform: this.platform,
				isError: true,
				message: error.message || "An error occurred during the listing process",
			};
		}
	}
}

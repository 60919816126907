import {Listing} from "../utils/types";
import {userStore} from "../state/User";
import {createListing, updateListing} from "../api/listings";
import {generateUUID} from "../utils/data";
import {listingsStore} from "../state/Listing";
import notificationStore from "../state/PopupNotification";
import {User} from "app-ts-types/users";
import {updateUser} from "../api/user";
import {ListingAccountVerificationCheckType, ListingAPIResponseType} from "../types";

export class BasePlatformOperations {
	platform: string = "";
	readonly listing: Listing;
	private readonly newListingTempId = generateUUID();
	private readonly inventoryId: string;

	constructor(listing:Listing, inventoryId:string) {
		this.listing = listing;
		this.inventoryId = inventoryId;
		this.listing.id = this.listing.id || this.newListingTempId;
	}

	init = async (): Promise<ListingAPIResponseType> => {
		return {
			success: false,
			isError: true,
			listingId: this.listing.id || this.newListingTempId,
			message: "platform listing process not configured",
			platform: this.platform,
			data: undefined,
		}
	}

	updateListing = async ():Promise<any> => {};

	deListListing = async ():Promise<any> => {};

	reListListing = async ():Promise<any> => {};

	deleteListing = async ():Promise<any> => {};

	// create or update a listing function
	// save the listing on the backend
	createOrUpdateListingFunction = async (): Promise<any> => {
		this.listing.user = userStore.getUser()?.id as string;
		const currentTime = new Date().getTime();
		this.listing.updatedAt = currentTime;
		this.listing.inventoryId = this.inventoryId;

		const func = this.listing.id ? updateListing.bind(this, this.listing.id) : createListing;

		return func({ ...this.listing, id: this.listing.id || this.newListingTempId, createdAt: this.listing.createdAt || currentTime })
			.then((res: { [key: string]: any }) => {
				if (!res.isError) {
					listingsStore.updateListing(res.data as Listing);
					notificationStore.setNotification({
						show: true,
						autoClose: true,
						isError: false,
						title: `Listing Updated`,
						message: `Listing information successfully updated`
					});
				} else {
					notificationStore.setNotification({
						autoClose: true,
						show: true,
						isError: true,
						title: `Error ${this.listing.id ? 'updating' : 'creating'} listing object`,
						message: res.error?.errorMessage || res.message
					});
					console.log(res);
				}
				return res;
			});
	};

	removeCachedListingObjectInStore = () => {
		listingsStore.deleteListing(this.listing.id || this.newListingTempId);
	}

	cacheListingObjectInStore = () => {
		this.listing.user = userStore.getUser()?.id as string;
		const currentTime = new Date().getTime();
		this.listing.updatedAt = currentTime;
		this.listing.inventoryId = this.inventoryId;
		const data = { ...this.listing, id: this.listing.id || this.newListingTempId, createdAt: this.listing.createdAt || currentTime }
		if (this.listing.id) {
			listingsStore.updateListing(data as Listing);
		} else {
			listingsStore.addListings([data]);
		}
	}

	// when we happen to update the token of a user
	// let's update that token within the store and the backend.
	updateUserOnTokenRefresh = async (access_token: string, refresh_token:string, expiry: number): Promise<any> => {
		const user = userStore.getUser();
		const platformDetails = {...user?.platformDetails || {}, [this.platform]: {...user?.platformDetails?.[this.platform], access_token, refresh_token, expiry}}
		console.log({...user, platformDetails});
		window.localStorage.setItem('user', JSON.stringify({...user, platformDetails}))
		userStore.setUser({...user, platformDetails} as User)
	}

	updateUserObject = async (user:User) => {
		// Call your API function here
		await updateUser(user!.id, user)
			.then((res: any) => {
				if (!res.isError) {
					// @ts-ignore
					window.localStorage.setItem('user', JSON.stringify(user))
					userStore.setUser(user)
				} else {
					console.log("error updating user object");
					console.log(res);
				}
			})
			.catch((err:any) => {
				console.log("error updating user object");
				console.log(err);
			})
	}

	// let's check if the user is still connected to the platform
	// or if they need to refresh their connection.
	isUserStillConnected = async ():Promise<ListingAccountVerificationCheckType> => {
		// if this token is expired
		if(!this.isTokenExpired(userStore.user?.platformDetails?.[this.platform]?.expiry)){
			return {
				isConnected: true,
				message: `Account connection is valid for platform: ${this.platform}`,
				platform: this.platform,
			}
		}
		return this.refreshToken();
	}

	/**
	 * Helper method to check if a token is expired.
	 */
	private isTokenExpired(expiry: number|undefined): boolean {
		if(!expiry) return true
		// Convert expiry from seconds to milliseconds
		return (expiry * 1000) < Date.now();
	}

	/**
	 * refreshToken
	 * this is the function to refresh any expired tokens for all the platforms
	 * Ideally, should be inherited and updated by the custom classes
	 * */
	refreshToken = async ():Promise<ListingAccountVerificationCheckType> => {
		return {
			isConnected: false,
			platform: this.platform,
			message: `Account Access token expired or invalid for platform: ${this.platform}`,
			accessToken: undefined,
			expiry: 0,
		}
	}
}
